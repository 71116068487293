<template>
    <v-row class="user-preview">
      <v-col
        cols="12"
        md="12"
      >
        <user-editable :data="userData">
        </user-editable>
      </v-col>
      
    </v-row>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiCurrencyUsd, mdiCurrencyUsdOff, mdiShare, mdiShareOff } from '@mdi/js'
import { onUnmounted, ref } from 'vue'
import moment from 'moment'

import UserEditable from '../UserEditable.vue'
// import materialStoreModule from '../materialStoreModule'

export default {
  components: {
    UserEditable,
  },
  setup() {
    

    const userData = ref({})
    // User Info
    const userInfo = JSON.parse(localStorage.getItem('userData'))
    if (userInfo !== null && userInfo !== undefined) {
      if (userInfo.info !== null && userInfo.info !== undefined) {
        userData.value = userInfo.info
        userData.value.uid = userInfo.user.uid
      }
    }

    return {
      userData,

      // Icons
      icons: {
        mdiShare,
        mdiCurrencyUsd,
        mdiShareOff,
        mdiCurrencyUsdOff,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>