<template>
    <v-card
      v-if="data"
      class="user-editable"
    >
      <v-dialog
        v-model="confirmDeleteDialog"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title class="headline">
            Account deletion confirmation
          </v-card-title>
          <v-card-text>
            This action is permanent, you WILL NOT be able to restore your account once it has been DELETED, nor will you  be able to restore any posts you have posted using this account. Please click on 'Confirm' to confirm the deletion. Otherwise click 'Cancel'
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="confirmDeletion"
            >
              Confirm
            </v-btn>
            <v-btn
              color="primary"
              @click="confirmDeleteDialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-form
            ref="userForm"
            class="multi-col-validation">

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="data.fullname"
                  label="Full name"
                  outlined
                  dense
                  placeholder="Full name"
                  hide-details="auto"
                  :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="data.phone"
                  label="Phone Number"
                  outlined
                  dense
                  placeholder="Phone Number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" v-if="errorText || successText">
                <p
                  v-if="errorText"
                  class="error--text font-weight-semibold"
                >
                  {{ errorText }}
                </p>

                <p
                  v-if="successText"
                  class="success--text font-weight-semibold"
                >
                  {{ successText }}
                </p>
              </v-col>

              <v-col cols="12" md="12" v-if="!inProgress">
                <v-btn
                  color="primary"
                  @click="update"
                >
                  Update
                </v-btn>
                &nbsp;&nbsp;
                <v-btn
                  color="success"
                  @click="resetPassword"
                >
                  Reset Password
                </v-btn>
                &nbsp;&nbsp;
                <v-btn
                  color="error"
                  @click="deleteAccount"
                >
                  Delete Account
                </v-btn>
              </v-col>
              <v-col cols="12" md="12" v-else>
                <VProgressCircular
                  indeterminate
                  color="primary"
                />
              </v-col>

            </v-row>
        </v-form> 

      </v-col>

    </v-row>

      
    </v-card>
  </template>
  
<script>
  import store from '@/store'
  import data from '@/views/tables/datatable/datatable'
  import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
  import { initialAbility } from '@/plugins/acl/config'
  import { required, requiredSelect } from '@core/utils/validation'
  import { mdiClose, mdiCogOutline, mdiCalendar } from '@mdi/js'
  import { onUnmounted, ref, getCurrentInstance } from 'vue'
  import Autocomplete from 'vue3-google-maps/dist/components/autocomplete.vue'
  import userStoreModule from './userStoreModule'
  import materialStoreModule from '../../apps/material/materialStoreModule'
  import authuserStoreModule from '../../apps/authuser/authuserStoreModule'
  
  export default {
    components: { Autocomplete, AppCardActions },
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const inProgress = ref(false)
      const confirmDeleteDialog = ref(false)
      const errorText = ref(null)
      const successText = ref(null)
      const userForm = ref(null)

      const vm = getCurrentInstance().proxy

      const AUTHUSER_APP_STORE_MODULE_NAME = 'app-authuser'
      const USER_APP_STORE_MODULE_NAME = 'app-user'
      const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'

      // Register module
      if (!store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) {
        store.registerModule(AUTHUSER_APP_STORE_MODULE_NAME, authuserStoreModule)
      }
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
      }
      if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) {
        store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
      }

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHUSER_APP_STORE_MODULE_NAME)
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
      })

      const logoutUser = async () => {
        // Remove userData from localStorage
        // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem('accessToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')
        localStorage.removeItem('materials-list')
        localStorage.removeItem('published-materials')

        // Reset ability
        vm.$ability.update(initialAbility)

        await store.dispatch('app-authuser/logOut').then(() => {
          // Redirect to homepage
          location.href = "/"
        })
      }

      const clearSuccessText = ({logout}) => {
        setTimeout(() => {
          successText.value = null
          if (logout) {
            logoutUser()
          }
        }, 5000)
      }

      const updateUserInfo = (payload) => {
        const userInfo = JSON.parse(localStorage.getItem('userData'))
        if (userInfo !== null && userInfo !== undefined) {
          if (userInfo.info !== null && userInfo.info !== undefined) {
            userInfo.info.fullname = payload["fullname"]
            userInfo.info.phone = payload["phone"]

            localStorage.setItem('userData', JSON.stringify(userInfo))
          }
        }
      }

      const resetPassword = async () => {
        errorText.value = null
        
        if (props.data.email === null || props.data.email === undefined) return

        console.log(props.data.email)
        await store
          .dispatch('app-authuser/passwordReset', {
            email: props.data.email,
          })
          .then(response => {
            console.log(response)
            successText.value = `Password reset link sent to ${props.data.email}`
            clearSuccessText()
          })
          .catch(err => {
            errorText.value = 'Oops, Unable to reset password'

            // console.error()
            console.log('error :>> ', err.message)
          })
      }

      const update = async () => {
        const isFormValid = userForm.value.validate()

        if (!isFormValid) return 
        
        inProgress.value = true

        let phone = props.data.phone
        if (phone === null || phone === undefined || phone.length === 0) {
          phone = ''
        }

        const payload = {
          "fullname": props.data.fullname,
          "phone": phone,
        }
        const userData = {
          "uid": props.data.uid,
          "payload": payload,
        }

        await store
          .dispatch('app-user/updateUser', userData,)
          .then(response => {
            inProgress.value = false

            successText.value = 'Update success'
            clearSuccessText()
            // Update local storage with payload
            updateUserInfo(payload)
          })
          .catch(err => {
            inProgress.value = false
            errorText.value = 'Oops, Unable to update user info'
            console.log('error :>> ', err.message)
          })
      }

      const deleteUser = async () => {
        const userData = {
          "uid": props.data.uid,
          "payload": {
            "deleted": true,
          },
        }

        await store
          .dispatch('app-user/updateUser', userData,)
          .then(response => {
            inProgress.value = false
            confirmDeleteDialog.value = false

            successText.value = 'Account deletion success'
            clearSuccessText({logout: true})
          })
          .catch(err => {
            inProgress.value = false
            confirmDeleteDialog.value = false

            errorText.value = 'Oops, Unable to delete account'
            console.log('error :>> ', err.message)
          })
      }

      const parseMaterials = async (items, idx) => {
        if (idx < items.length) {
          await store
          .dispatch('app-material/deleteMaterial', items[idx])
          .then(response => {
            parseMaterials(items, idx + 1)
          })
          .catch(err => {
            inProgress.value = false
            confirmDeleteDialog.value = false

            errorText.value = `Oops, Unable to delete material: ${items[idx].title}`
            console.log('error :>> ', err.message)
          })
        } else {
          console.log("Delete Account Ready")
          deleteUser()
        }
      }

      const deleteAccount = () => {
        confirmDeleteDialog.value = true;
      }

      const confirmDeletion = async () => {
        inProgress.value = true

        await store
          .dispatch('app-material/fetchMaterialsByUser',)
          .then(response => {
            parseMaterials(response, 0)    
          })
          .catch(err => {
            inProgress.value = false
            confirmDeleteDialog.value = false

            errorText.value = 'Oops, Unable to delete account'
            console.log('error :>> ', err.message)
          })
      }

      
      return {
        validators: {
          required,
          requiredSelect,
        },
        confirmDeleteDialog,
        errorText,
        successText,
        userForm,
        inProgress,
        update,
        resetPassword,
        deleteAccount,
        confirmDeletion,
  
        // Icons
        icons: {
          mdiClose,
          mdiCogOutline,
          mdiCalendar,
        },
      }
    },
  }
</script>
  
<style lang="scss" scoped>
@import '~@core/preset/preset/apps/invoice.scss';

.user-editable {
  padding: 25px;
}
</style>
  